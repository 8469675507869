export class MonetaryAmount {
  constructor(amount: number) {
    if (isNaN(amount)) {
      this.amount = 0;
    } else {
      this.amount = amount;
    }
  }
  amount: number;

  copy() {
    return new MonetaryAmount(this.amount);
  }
  add(o: MonetaryAmount) {
    return new MonetaryAmount(this.amount + o.amount);
  }
  sub(o: MonetaryAmount) {
    return new MonetaryAmount(this.amount - o.amount);
  }
  neg() {
    return new MonetaryAmount(-this.amount);
  }
  isNeg() {
    return this.amount < 0;
  }
  mul(m: number, rounding = 0) {
    const roundingFactor = Math.pow(10, rounding);
    return new MonetaryAmount(
      Math.round(((this.amount * m) / roundingFactor) * roundingFactor)
    );
  }
  div(o: MonetaryAmount) {
    return this.amount / o.amount;
  }
  divNum(o: number, rounding = 0) {
    const roundingFactor = Math.pow(10, rounding);
    return new MonetaryAmount(
      Math.round((this.amount / o / roundingFactor) * roundingFactor)
    );
  }
  zero() {
    return this.amount === 0;
  }
  equal(o: MonetaryAmount) {
    return this.amount === o.amount;
  }
}

export class MonetaryAmountWithDiff {
  constructor(current: MonetaryAmount, staged: MonetaryAmount) {
    this.current = current;
    this.staged = staged;
    this.diff = staged.sub(current);
  }

  current: MonetaryAmount;
  staged: MonetaryAmount;
  diff: MonetaryAmount;

  add(o: MonetaryAmountWithDiff): MonetaryAmountWithDiff {
    return new MonetaryAmountWithDiff(
      this.current.add(o.current),
      this.staged.add(o.staged)
    );
  }
  div(o: MonetaryAmountWithDiff): { current: number; staged: number } {
    return {
      current: this.current.div(o.current),
      staged: this.staged.div(o.staged),
    };
  }

  hasStagedChanges(): boolean {
    return !this.diff.zero();
  }
}
