import { Household } from "../budgets/households/household";
import {
  HouseholdTypeDto,
  MultiHouseholdDto,
  MultiHouseholdEnvelope,
  SingleHouseholdDto,
  SingleHouseholdEnvelope,
} from "../budgets/dtos";

export interface CoreHouseholdData {
  id: string;
  name: string;
  currency: string;
}

export interface MultiHouseholdData
  extends CoreHouseholdData,
    MultiHouseholdEnvelope {}

export interface SingleHouseholdData
  extends CoreHouseholdData,
    SingleHouseholdEnvelope {}

export type HouseholdData = MultiHouseholdData | SingleHouseholdData;

export interface HouseholdUser {
  id: string;
  nickname: string;
  scopes: string[];
}

export const householdToDto = (
  prev: CoreHouseholdData,
  hh: Household<any>
): HouseholdData => {
  return {
    id: prev.id,
    name: prev.name,
    currency: prev.currency,
    ...hh.toDto(),
  };
};

export interface CoreHousehold {
  name: string;
  currency: string;
}

export interface UpdateMultiHousehold extends CoreHousehold {
  type: HouseholdTypeDto.multi;
  data: MultiHouseholdDto;
}

export interface UpdateSingleHousehold extends CoreHousehold {
  type: HouseholdTypeDto.single;
  data: SingleHouseholdDto;
}

export interface CreateHousehold {
  nickname: string;
}

export interface CreateMultiHousehold
  extends UpdateMultiHousehold,
    CreateHousehold {}

export interface CreateSingleHousehold
  extends UpdateSingleHousehold,
    CreateHousehold {}

const createClient = ({ url }: { url: string }) => {
  const aFetch = async (
    token: string,
    path: string,
    opts: { [k: string]: any } = {}
  ): Promise<Response> => {
    return await fetch(`${url}/api/v1${path}`, {
      ...opts,
      headers: {
        Authorization: `Bearer ${token}`,
        ...opts.headers,
      },
    });
  };

  const getHousehold = async (
    token: string,
    id: string
  ): Promise<HouseholdData> =>
    await aFetch(token, `/${id}`).then((r) => r.json());

  return {
    getHouseholds: async (token: string): Promise<HouseholdData[]> => {
      const ids = await aFetch(token, "/").then((r) => r.json());
      return await Promise.all(
        ids.map((id: string) => getHousehold(token, id))
      );
    },

    getHousehold,

    getUsers: async (token: string, id: string): Promise<HouseholdUser[]> =>
      await aFetch(token, `/${id}/users`).then((r) => r.json()),

    updateUserScopes: async (
      token: string,
      id: string,
      userId: string,
      scopes: string[]
    ) =>
      await aFetch(token, `/${id}/users/${userId}`, {
        method: "PATCH",
        body: JSON.stringify({ scopes }),
      }),

    createHousehold: async (
      token: string,
      props: CreateSingleHousehold | CreateMultiHousehold
    ) =>
      await aFetch(token, "/", {
        method: "POST",
        body: JSON.stringify({
          household: {
            type: props.type,
            name: props.name,
            currency: props.currency,
            data: props.data,
          },
          nickname: props.nickname,
        }),
      }),

    updateHousehold: async (
      token: string,
      id: string,
      props: UpdateSingleHousehold | UpdateMultiHousehold
    ) =>
      await aFetch(token, `/${id}`, {
        method: "PATCH",
        body: JSON.stringify({
          type: props.type,
          name: props.name,
          currency: props.currency,
          data: props.data,
        }),
      }),

    createInvite: async (token: string, householdId: string): Promise<string> =>
      await aFetch(token, `/${householdId}/invites`, {
        method: "POST",
      })
        .then((r) => r.json())
        .then((r) => r.code),

    getInvites: async (token: string, householdId: string): Promise<string[]> =>
      await aFetch(token, `/${householdId}/invites`).then((r) => r.json()),

    claimInvite: async (
      token: string,
      props: { code: string; nickname: string }
    ) =>
      await aFetch(token, `/invites/`, {
        method: "POST",
        body: JSON.stringify(props),
      }),
  };
};

const client = createClient({
  // url: "http://localhost:3001",
  // url: "https://pefi.simonschneider.org",
  // url: "https://pefi-6r73dimkvq-lz.a.run.app",
  url: "",
});

export default client;
