import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { FetchState, withLoading } from "./utils/tryWithState";
import client, { HouseholdData, HouseholdUser } from "./utils/client";
import Loading from "./components/Loading";
import {
  Button,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const CreateInviteButton: React.FC<{ id: string }> = ({ id }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [loading, setLoading] = useState<FetchState>();
  const [code, setCode] = useState<string | undefined>();

  const createInvite = withLoading(setLoading, async (id: string) => {
    const token = await getAccessTokenSilently();
    const code = await client.createInvite(token, id);
    setCode(code);
  });
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => createInvite(id)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.margin}
      >
        <AddIcon />
      </Fab>
      <Modal open={code != null} onClose={() => setCode(undefined)}>
        <div style={modalStyle} className={classes.paper}>
          <span>{code}</span>
        </div>
      </Modal>
    </div>
  );
};

const EditUserModal: React.FC<{
  user: HouseholdUser;
  changeUserScopes: (user: string[]) => Promise<void>;
}> = ({ user, changeUserScopes }) => {
  const classes = useStyles();
  const [scopes, setScopes] = useState<string[]>(user.scopes);
  const [loading, setLoading] = useState<boolean>(false);
  const scopeCheckboxes = ["reader", "writer", "owner"].map((s) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={scopes.includes(s)}
            onChange={(e) => {
              if (e.target.checked) {
                setScopes(Array.from(new Set([...scopes, s]).values()));
              } else {
                setScopes(scopes.filter((sc) => sc !== s));
              }
            }}
          />
        }
        label={s}
      />
    );
  });

  return (
    <div style={modalStyle} className={classes.paper}>
      <h2>Edit user</h2>
      <form style={{ display: "flex", flexDirection: "column" }}>
        {scopeCheckboxes}
        {loading ? (
          <Loading />
        ) : (
          <Button
            onClick={() => {
              setLoading(true);
              changeUserScopes(scopes).then(() => setLoading(false));
            }}
          >
            OK
          </Button>
        )}
      </form>
    </div>
  );
};

const EditUserButton: React.FC<{
  user: HouseholdUser;
  changeUserScopes: (userId: string, scopes: string[]) => Promise<void>;
}> = ({ user, changeUserScopes }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <IconButton edge="end" aria-label="manage" onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <EditUserModal
          user={user}
          changeUserScopes={async (u) => {
            await changeUserScopes(user.id, u);
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

const HouseholdManagement: React.FC<{ id: string }> = ({ id }) => {
  const classes = useStyles();
  const { getAccessTokenSilently, user: thisUser } = useAuth0();
  const [household, setHousehold] = useState<HouseholdData>();
  const [users, setUsers] = useState<HouseholdUser[]>();
  const [invites, setInvites] = useState<string[]>();
  const [loading, setLoading] = useState<FetchState>();

  const getHousehold = withLoading(
    setLoading,
    async (id: string, getAccessTokenSilently: () => Promise<string>) => {
      const token = await getAccessTokenSilently();
      const [h, u, i] = await Promise.allSettled([
        client.getHousehold(token, id),
        client.getUsers(token, id),
        client.getInvites(token, id),
      ]);
      if (h.status === "fulfilled") {
        setHousehold(h.value);
      }
      if (u.status === "fulfilled") {
        setUsers(u.value);
      }
      if (i.status === "fulfilled") {
        setInvites(i.value);
      } else {
        setInvites([]);
      }
    }
  );

  useEffect(() => {
    getHousehold(id, getAccessTokenSilently);
  }, [id, getAccessTokenSilently]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading === FetchState.FETCHING) {
    return <Loading />;
  }
  if (household == null || users == null || invites == null) {
    return <Loading />;
  }
  const isOwner =
    users.find((u) => u.id === thisUser?.sub)?.scopes?.includes("owner") ??
    false;

  const handleChangeUserScopes = async (userId: string, scopes: string[]) => {
    const token = await getAccessTokenSilently();
    await client.updateUserScopes(token, id, userId, scopes);
    await getHousehold(id, getAccessTokenSilently);
  };

  const userList = users
    .map((user) => (
      <ListItem key={user.id}>
        <ListItemText
          primary={user.nickname}
          secondary={user.scopes.join(", ")}
        />
        {user.id !== thisUser?.sub && (
          <ListItemSecondaryAction>
            <EditUserButton
              user={user}
              changeUserScopes={handleChangeUserScopes}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    ))
    .reduce((acc: JSX.Element[], elem: JSX.Element) => {
      return acc.length === 0
        ? [elem]
        : [...acc, <Divider key={`div-${elem.key}`} />, elem];
    }, []);

  const inviteList = invites
    .map((invite) => (
      <ListItem key={invite}>
        <ListItemText primary={invite} />
      </ListItem>
    ))
    .reduce((acc: JSX.Element[], elem: JSX.Element) => {
      return acc.length === 0
        ? [elem]
        : [...acc, <Divider key={`div-${elem.key}`} />, elem];
    }, []);

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {household.name}
      </Typography>
      <Divider />
      <Typography variant="h6" className={classes.title}>
        Users
      </Typography>
      <List>{userList}</List>
      {isOwner && (
        <div>
          <Typography variant="h6" className={classes.title}>
            Invites
          </Typography>
          <List>{inviteList}</List>
          <CreateInviteButton id={id} />
        </div>
      )}
    </div>
  );
};

export default HouseholdManagement;
