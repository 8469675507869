import { MonetaryAmount, MonetaryAmountWithDiff } from "./monetaryAmount";
import { AccountProps } from "./household";

export class Account {
  constructor(props: AccountProps, amount: MonetaryAmount) {
    this.props = props;
    this.amount = amount;
  }

  props: AccountProps;
  amount: MonetaryAmount;

  plus(o: Account) {
    return new Account(this.props, this.amount.add(o.amount));
  }

  diff(o: Account) {
    return new AccountDiff(
      this.props,
      new MonetaryAmountWithDiff(this.amount, o.amount)
    );
  }
}

export class AccountDiff {
  constructor(props: AccountProps, amount: MonetaryAmountWithDiff) {
    this.props = props;
    this.amount = amount;
  }

  props: AccountProps;
  amount: MonetaryAmountWithDiff;

  plus(o: AccountDiff) {
    return new AccountDiff(this.props, this.amount.add(o.amount));
  }

  prefix(prefix: string) {
    return new AccountDiff(
      { ...this.props, name: prefix + this.props.name },
      this.amount
    );
  }

  hasStagedChanges() {
    return this.amount.hasStagedChanges();
  }
}

export class Accounts {
  constructor(...accounts: Account[]) {
    this.accounts = accounts;
  }

  accounts: Account[];

  plus(o: Accounts) {
    if (this.accounts.length !== o.accounts.length) {
      throw new Error("non equal account lengths");
    }
    return new Accounts(...this.accounts.map((a, i) => a.plus(o.accounts[i])));
  }

  diff(o: Accounts) {
    if (this.accounts.length !== o.accounts.length) {
      throw new Error("non equal account lengths");
    }
    return new AccountsDiff(
      ...this.accounts.map((a, i) => a.diff(o.accounts[i]))
    );
  }
}

export class AccountsDiff {
  constructor(...accounts: AccountDiff[]) {
    this.accounts = accounts;
  }

  accounts: AccountDiff[];

  plus(o: AccountsDiff) {
    if (o.accounts.length !== this.accounts.length) {
      throw new Error("non equal account lengths");
    }
    return new AccountsDiff(
      ...this.accounts.map((a, i) => a.plus(o.accounts[i]))
    );
  }

  toList(prefix = "") {
    return this.accounts.map((a) => a.prefix(prefix));
  }
}
