import {
  Button,
  Fab,
  FormHelperText,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useState, MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import client from "./utils/client";
import { useAuth0 } from "@auth0/auth0-react";
import { FetchState, withLoading } from "./utils/tryWithState";
import Loading from "./components/Loading";
import { HouseholdTypeDto, ShareMethodTypeDto } from "./budgets/dtos";

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UseInvite: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [props, setProps] = useState<{ nickname?: string; code?: string }>({});
  const [loading, setLoading] = useState<FetchState>(FetchState.IDLE);

  const claimInvite = withLoading(setLoading, async () => {
    const token = await getAccessTokenSilently();
    await client.claimInvite(token, {
      code: props.code!,
      nickname: props.nickname!,
    });
    onClose();
  });

  return (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Use Invite</h2>
      <form autoComplete="off">
        <TextField
          id="standard-basic"
          label="Code"
          aria-describedby="code-helper-text"
          onChange={(event) => {
            setProps({ ...props, code: event.target.value });
          }}
        />
        <FormHelperText id="nickname-helper-text">
          Then invite code
        </FormHelperText>
        <TextField
          id="standard-basic"
          label="Nickname"
          aria-describedby="nickname-helper-text"
          onChange={(event) => {
            setProps({ ...props, nickname: event.target.value });
          }}
        />
        <FormHelperText id="nickname-helper-text">
          Your Nickname for this household
        </FormHelperText>
        {loading ? (
          <Loading />
        ) : (
          <Button onClick={() => claimInvite()}>Claim</Button>
        )}
      </form>
    </div>
  );
};

const CreateHousehold: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const [props, setProps] = useState<{ nickname?: string; name?: string }>({});
  const [loading, setLoading] = useState<FetchState>(FetchState.IDLE);

  const createHousehold = withLoading(setLoading, async () => {
    const token = await getAccessTokenSilently();
    await client.createHousehold(token, {
      nickname: props.nickname!,
      name: props.name!,
      currency: "SEK",
      type: HouseholdTypeDto.multi,
      data: {
        accounts_split: {
          bills_amount: 0,
          savings_percentage: 0,
          spending_amount: 0,
        },
        contributors: [],
        share_allowance: 0,
        share_amount: 0,
        share_method: ShareMethodTypeDto.relative,
        share_percentage: 0.46,
      },
    });
    onClose();
  });

  return (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Create Household</h2>
      <form autoComplete="off">
        <TextField
          id="standard-basic"
          label="Nickname"
          aria-describedby="nickname-helper-text"
          onChange={(event) => {
            setProps({ ...props, nickname: event.target.value });
          }}
        />
        <FormHelperText id="nickname-helper-text">
          Your Nickname for this household
        </FormHelperText>
        <TextField
          id="standard-basic"
          label="Name"
          aria-describedby="household-helper-text"
          onChange={(event) => {
            setProps({ ...props, name: event.target.value });
          }}
        />
        <FormHelperText id="nickname-helper-text">
          Name of the household
        </FormHelperText>
        {loading ? (
          <Loading />
        ) : (
          <Button onClick={() => createHousehold()}>Create</Button>
        )}
      </form>
    </div>
  );
};

const AddHousehold = () => {
  const classes = useStyles();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleMenuClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openUseInvite = () => {
    setShowInviteModal(true);
    handleMenuClose();
  };

  const openCreateHousehold = () => {
    setShowCreateModal(true);
    handleMenuClose();
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        aria-haspopup="true"
        onClick={handleMenuClick}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.margin}
      >
        <AddIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={openUseInvite}>Use Invite</MenuItem>
        <MenuItem onClick={openCreateHousehold}>New Household</MenuItem>
      </Menu>
      <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <CreateHousehold onClose={() => setShowCreateModal(false)} />
      </Modal>
      <Modal open={showInviteModal} onClose={() => setShowInviteModal(false)}>
        <UseInvite onClose={() => setShowInviteModal(false)} />
      </Modal>
    </div>
  );
};

export default AddHousehold;
