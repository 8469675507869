import { AccountProps } from "./household";

export const Pefi: { [key: string]: AccountProps } = {
  spending: {
    icon: "credit_card",
    color: "blue",
    name: "Spending",
  },
  bills: {
    icon: "mail",
    color: "orange",
    name: "Bills",
  },
  savings: {
    icon: "account_balance",
    color: "green",
    name: "Savings",
  },
  shortSavings: {
    icon: "attach_money",
    color: "lightGreen",
    name: "Short Savings",
  },
};
