import React from "react";
import { Divider, List } from "@material-ui/core";
import AccountDiffListElement from "./AccountDiffListElement";
import { BudgetDiff } from "../budgets/household";

const AccountsListWithHeader: React.FC<{
  currency: string;
  budget: BudgetDiff;
}> = ({ currency, budget, children }) => {
  const total = budget.total();
  return (
    <List>
      {[
        children,
        <Divider key="children-div" />,
        budget.accounts.map((a) => (
          <AccountDiffListElement
            key={a.props.name}
            currency={currency}
            account={a}
            total={total}
          />
        )),
      ]}
    </List>
  );
};

export default AccountsListWithHeader;
