import { MonetaryAmount } from "./monetaryAmount";
import { nanFallback } from "../utils/nan-fallback";

interface MoneyFormatter {
  (m: MonetaryAmount): string;
}

const postfix = (s: string) => (m: MonetaryAmount) =>
  `${nanFallback(m.amount)}${s}`;
const prefix = (s: string) => (m: MonetaryAmount) =>
  `${s}${nanFallback(m.amount)}`;

const formatters: { [k: string]: MoneyFormatter } = {
  SEK: postfix(" kr"),
  USD: prefix("$"),
};

export const formatter = (currency: string): MoneyFormatter => {
  return formatters[currency] ?? postfix(` ${currency}`);
};
