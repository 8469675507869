import React, { useEffect, useState } from "react";
import { FetchState, withLoading } from "./utils/tryWithState";
import Loading from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import client, { HouseholdData, householdToDto } from "./utils/client";
import MultiHousehold from "./MultiHousehold";
import { Household as HouseholdDomain } from "./budgets/households/household";
import SingleHousehold from "./SingleHousehold";
import { MultiHousehold as MultiH } from "./budgets/households/multiHousehold";
import { SingleHousehold as SingleH } from "./budgets/households/singleHousehold";
import { mapHouseholdDto } from "./budgets/dtos";

const Household: React.FC<{ id: string }> = ({ id }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [householdData, setHouseholdData] = useState<HouseholdData>();
  const [household, setHousehold] = useState<HouseholdDomain<any>>();
  const [loading, setLoading] = useState<FetchState>();

  const getHousehold = withLoading(
    setLoading,
    async (id: string, getAccessTokenSilently: () => Promise<string>) => {
      const hData = await client.getHousehold(
        await getAccessTokenSilently(),
        id
      );
      setHousehold(mapHouseholdDto(hData));
      setHouseholdData(hData);
    }
  );

  const handleMultiUpdateHousehold = (h: MultiH) => {
    setHousehold(h);
    getAccessTokenSilently().then((t) =>
      client.updateHousehold(t, id, householdToDto(householdData!, h))
    );
  };

  useEffect(() => {
    getHousehold(id, getAccessTokenSilently);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    loading === FetchState.FETCHING ||
    household == null ||
    householdData == null
  ) {
    return <Loading />;
  }

  if (household instanceof SingleH) {
    return (
      <SingleHousehold
        currency={householdData.currency}
        household={household as SingleH}
      />
    );
  } else if (household instanceof MultiH) {
    return (
      <MultiHousehold
        currency={householdData.currency}
        household={household as MultiH}
        updateHousehold={handleMultiUpdateHousehold}
      />
    );
  }
  throw new Error("bla");
};

export default Household;
