import AppBar from "./AppBar";
import React from "react";

const WithAppBar: React.FC = ({ children }) => {
  return (
    <div>
      <AppBar />
      {children}
    </div>
  );
};

export default WithAppBar;
