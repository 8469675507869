import { AccountDiff as AccDiff } from "../budgets/accountSplit";
import React from "react";
import { Icon } from "@material-ui/core";
import MoneyWithDiff from "./MoneyWithDiff";
import { MonetaryAmountWithDiff } from "../budgets/monetaryAmount";
import PefiListItem from "./PefiListItem";
import { nanFallback } from "../utils/nan-fallback";

const percentage = (n: number, rounding = 0) => {
  const roundingFactor = Math.pow(10, rounding);
  return Math.round(((n * 100) / roundingFactor) * roundingFactor);
};

const AccountDiffListElement: React.FC<{
  currency: string;
  account: AccDiff;
  total: MonetaryAmountWithDiff;
}> = ({ currency, account, total }) => {
  const diff = account.amount.div(total);
  return (
    <PefiListItem
      icon={
        <Icon style={{ color: account.props.color }}>{account.props.icon}</Icon>
      }
      heading={account.props.name}
      subHeading={
        account.hasStagedChanges()
          ? `${nanFallback(percentage(diff.current))}% (${nanFallback(
              percentage(diff.staged)
            )}%)`
          : `${nanFallback(percentage(diff.current))}%`
      }
      right={<MoneyWithDiff currency={currency} amount={account.amount} />}
    />
  );
};

export default AccountDiffListElement;
