import React, { useState } from "react";
import { ContributorBudgetDiff } from "./budgets/household";
import { SingleHousehold as SingleH } from "./budgets/households/singleHousehold";
import { MonetaryAmount } from "./budgets/monetaryAmount";
import ContributorDetails, { ContributorChanges } from "./ContributorDetails";

const SingleHousehold: React.FC<{ currency: string; household: SingleH }> = ({
  currency,
  household,
}) => {
  const [staged, setStaged] = useState<SingleH>(household.copy());

  const cont = staged.toDto().data.contributor;
  const budget = household.getBudget().diff(staged.getBudget()).toBudgets()[0];

  const handleContributorChanges = (
    contributorId: string,
    c: ContributorChanges
  ) => {
    setStaged(
      staged.copy({
        contributor: {
          income: c.income,
          accounts: {
            bills: c.billsAmount,
            savings: c.savingsPercentage,
            spending: c.spendingAmount,
          },
        },
      })
    );
  };

  return (
    <ContributorDetails
      currency={currency}
      budget={budget as ContributorBudgetDiff}
      details={{
        income: new MonetaryAmount(cont.income),
        billsAmount: new MonetaryAmount(cont.accounts.bills_amount),
        savingsPercentage: cont.accounts.savings_percentage,
        spendingAmount: new MonetaryAmount(cont.accounts.spending_amount),
      }}
      stageChanges={handleContributorChanges}
    />
  );
};

export default SingleHousehold;
