import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Households from "./Households";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import Loading from "./components/Loading";
import Profile from "./Profile";
import WithAppBar from "./WithAppBar";
import Household from "./Household";
import HouseholdManagement from "./HouseholdManagement";

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  if (isLoading) {
    return <Loading />;
  }
  if (isAuthenticated && !isLoading) {
    history.push("/households");
  }
  return <div />;
};

const HouseholdRoute = () => {
  const { householdId } = useParams<{ householdId: string }>();
  return <Household id={householdId} />;
};

const HouseholdManagementRoute = () => {
  const { householdId } = useParams<{ householdId: string }>();
  return <HouseholdManagement id={householdId} />;
};

function App() {
  return (
    <Router>
      <WithAppBar>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/profile"
            component={withAuthenticationRequired(Profile)}
          />
          <Route
            path="/households/:householdId/manage"
            component={withAuthenticationRequired(HouseholdManagementRoute)}
          />
          <Route
            path="/households/:householdId"
            component={withAuthenticationRequired(HouseholdRoute)}
          />
          <Route
            path="/households"
            component={withAuthenticationRequired(Households)}
          />
        </Switch>
      </WithAppBar>
    </Router>
  );
}

export default App;
