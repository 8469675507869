import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  BudgetDiff,
  ContributorBudgetDiff,
  SharedBudgetDiff,
} from "./budgets/household";
import { MultiHousehold as MultiH } from "./budgets/households/multiHousehold";
import React, { useMemo, useState } from "react";
import { Fab, Paper, Tab, Tabs, useTheme } from "@material-ui/core";
import ContributorDetails, { ContributorChanges } from "./ContributorDetails";
import CheckIcon from "@material-ui/icons/Check";
import UndoIcon from "@material-ui/icons/Undo";
import SharedDetails, { SharedChanges } from "./SharedDetails";
import { MonetaryAmount } from "./budgets/monetaryAmount";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const TabPanel: React.FC<{ value: number; index: number; [k: string]: any }> =
  ({ value, index, children, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Paper>{children}</Paper>}
      </div>
    );
  };

const isContributorBudgetDiff = (e: BudgetDiff): e is ContributorBudgetDiff => {
  return e instanceof ContributorBudgetDiff;
};

const isSharedBudgetDiff = (e: BudgetDiff): e is SharedBudgetDiff => {
  return e instanceof SharedBudgetDiff;
};

const MultiHousehold: React.FC<{
  currency: string;
  household: MultiH;
  updateHousehold: (h: MultiH) => void;
}> = ({ currency, household, updateHousehold }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [staged, setStaged] = useState<MultiH>(household);
  const budgets = useMemo(
    () => household.getBudget().diff(staged.getBudget()).toBudgets(),
    [staged, household]
  );
  const { hasChanges, contTabs, sharedTab } = useMemo(() => {
    return {
      hasChanges: budgets.some((b) => b.hasStagedChanges()),
      contTabs: budgets.filter(isContributorBudgetDiff),
      sharedTab: budgets.find(isSharedBudgetDiff)!,
    };
  }, [budgets]);

  const stagedDto = useMemo(() => staged.toDto().data, [staged]);

  const addContributor = (id: string, name: string) => {
    updateHousehold(staged.addContributor(id, name));
  };

  const handleContributorChanges = (
    contributorId: string,
    c: ContributorChanges
  ) => {
    setStaged(
      staged.copy({
        contributors: {
          [contributorId]: {
            accounts: {
              bills: c.billsAmount,
              savings: c.savingsPercentage,
              spending: c.spendingAmount,
            },
            income: c.income,
            extraContributions: c.extraContributions,
          },
        },
      })
    );
  };

  const handleSharedChanges = (c: SharedChanges) => {
    setStaged(
      staged.copy({
        accounts: {
          bills: c.billsAmount,
          savings: c.savingsPercentage,
          spending: c.spendingAmount,
        },
        activeShareMethod: c.shareMethod,
        shareMethods: {
          absolute: c.shareAmount,
          allowance: c.shareAllowance,
          relative: c.sharePercentage,
        },
      })
    );
  };

  const tabs = useMemo(
    () =>
      [
        <SharedDetails
          currency={currency}
          budget={sharedTab}
          details={{
            shareMethod: stagedDto.share_method,
            shareAllowance: new MonetaryAmount(stagedDto.share_allowance),
            shareAmount: new MonetaryAmount(stagedDto.share_amount),
            sharePercentage: stagedDto.share_percentage,
            billsAmount: new MonetaryAmount(
              stagedDto.accounts_split.bills_amount
            ),
            savingsPercentage: stagedDto.accounts_split.savings_percentage,
            spendingAmount: new MonetaryAmount(
              stagedDto.accounts_split.spending_amount
            ),
          }}
          stageChanges={handleSharedChanges}
          addContributor={addContributor}
        />,
        ...contTabs.map((b) => {
          const cont = stagedDto.contributors.find((c) => c.id === b.id)!;
          return (
            <ContributorDetails
              currency={currency}
              budget={b}
              details={{
                income: new MonetaryAmount(cont.income),
                billsAmount: new MonetaryAmount(cont.accounts.bills_amount),
                savingsPercentage: cont.accounts.savings_percentage,
                spendingAmount: new MonetaryAmount(
                  cont.accounts.spending_amount
                ),
                extraContributions: new MonetaryAmount(
                  cont.extra_contributions
                ),
              }}
              stageChanges={handleContributorChanges}
            />
          );
        }),
      ].map((t, i) => (
        <TabPanel key={i} value={value} index={i}>
          {t}
        </TabPanel>
      )),
    [contTabs, currency, sharedTab, stagedDto, value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={(_, v) => setValue(v)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {budgets.map((b) => (
          <Tab key={b.name} label={b.name} />
        ))}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={(i: number) => setValue(i)}
      >
        {tabs}
      </SwipeableViews>
      {hasChanges && (
        <div>
          <Fab
            color="primary"
            aria-label="edit"
            aria-haspopup="true"
            onClick={() => updateHousehold(staged)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.margin}
          >
            <CheckIcon />
          </Fab>
          <Fab
            color="primary"
            aria-label="edit"
            aria-haspopup="true"
            onClick={() => {
              setStaged(household);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.margin}
          >
            <UndoIcon />
          </Fab>
        </div>
      )}
    </Paper>
  );
};

export default MultiHousehold;
