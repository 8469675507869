import { MonetaryAmount } from "./monetaryAmount";
import { AccountsSplit, Contributor } from "./household";
import {
  AbsoluteShareMethod,
  AllowanceShareMethod,
  RelativeShareMethod,
} from "./shareMethod";
import { MultiHousehold } from "./households/multiHousehold";
import { SingleHousehold } from "./households/singleHousehold";
import { Household } from "./households/household";

export interface AccountsSplitDto {
  spending_amount: number;
  bills_amount: number;
  savings_percentage: number;
}

const mapAccountsSplit = (data: AccountsSplitDto): AccountsSplit => {
  return AccountsSplit.from(
    new MonetaryAmount(data.spending_amount),
    new MonetaryAmount(data.bills_amount),
    data.savings_percentage
  );
};

export interface ContributorDto {
  id: string;
  name: string;
  income: number;
  accounts: AccountsSplitDto;
  extra_contributions: number;
}

const mapContributor = (c: ContributorDto): Contributor => {
  return new Contributor(
    c.id,
    c.name,
    new MonetaryAmount(c.income),
    mapAccountsSplit(c.accounts),
    new MonetaryAmount(c.extra_contributions)
  );
};

export enum ShareMethodTypeDto {
  absolute = "absolute",
  relative = "relative",
  allowance = "allowance",
}

export interface MultiHouseholdDto {
  share_method: ShareMethodTypeDto;
  share_amount: number;
  share_allowance: number;
  share_percentage: number;
  accounts_split: AccountsSplitDto;
  contributors: ContributorDto[];
}

const mapMultiHousehold = (h: MultiHouseholdDto): MultiHousehold => {
  return new MultiHousehold(
    h.contributors.map((c) => mapContributor(c)),
    h.share_method,
    [
      new RelativeShareMethod(h.share_percentage),
      new AllowanceShareMethod(new MonetaryAmount(h.share_allowance)),
      new AbsoluteShareMethod(new MonetaryAmount(h.share_amount)),
    ],
    mapAccountsSplit(h.accounts_split)
  );
};

export interface SingleHouseholdDto {
  contributor: ContributorDto;
}

const mapSingleHousehold = (h: SingleHouseholdDto): SingleHousehold => {
  return new SingleHousehold(mapContributor(h.contributor));
};

export interface MultiHouseholdEnvelope {
  type: HouseholdTypeDto.multi;
  data: MultiHouseholdDto;
}

export interface SingleHouseholdEnvelope {
  type: HouseholdTypeDto.single;
  data: SingleHouseholdDto;
}

export enum HouseholdTypeDto {
  multi = "multi",
  single = "single",
}

export const mapHouseholdDto = (
  envelope: MultiHouseholdEnvelope | SingleHouseholdEnvelope
): Household<any> => {
  switch (envelope.type) {
    case HouseholdTypeDto.multi:
      return mapMultiHousehold(envelope.data);
    case HouseholdTypeDto.single:
      return mapSingleHousehold(envelope.data);
    default:
      throw new Error("bla");
  }
};
