import PefiListItem from "./components/PefiListItem";
import MoneyWithDiff from "./components/MoneyWithDiff";
import { ContributorBudgetDiff } from "./budgets/household";
import React, { useState } from "react";
import AccountsListWithHeader from "./components/AccountsListWithHeader";
import { MonetaryAmount } from "./budgets/monetaryAmount";
import { Button, Fab, Modal, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export interface AccountsChanges {
  spendingAmount?: MonetaryAmount;
  billsAmount?: MonetaryAmount;
  savingsPercentage?: number;
}

export interface ContributorChanges extends AccountsChanges {
  income?: MonetaryAmount;
  extraContributions?: MonetaryAmount;
}

const ContributorDetails: React.FC<{
  currency: string;
  budget: ContributorBudgetDiff;
  details: ContributorChanges;
  stageChanges: (contributorId: string, c: ContributorChanges) => void;
}> = ({ currency, budget, details, stageChanges }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges] = useState<ContributorChanges>({ ...details });
  return (
    <div>
      <AccountsListWithHeader currency={currency} budget={budget}>
        <PefiListItem
          key={"income"}
          heading={"Income"}
          right={<MoneyWithDiff currency={currency} amount={budget.income} />}
        />
      </AccountsListWithHeader>
      <Fab
        color="primary"
        aria-label="edit"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.margin}
      >
        <EditIcon />
      </Fab>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Edit contributor</h2>
          <form
            autoComplete="off"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              label="Income"
              type="number"
              defaultValue={changes.income?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  income: new MonetaryAmount(parseInt(event.target.value)),
                });
              }}
            />
            <TextField
              label="Spending"
              type="number"
              defaultValue={changes.spendingAmount?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  spendingAmount: new MonetaryAmount(
                    parseInt(event.target.value)
                  ),
                });
              }}
            />
            <TextField
              label="Bills"
              type="number"
              defaultValue={changes.billsAmount?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  billsAmount: new MonetaryAmount(parseInt(event.target.value)),
                });
              }}
            />
            <TextField
              label="Savings %"
              type="number"
              defaultValue={100 * (changes.savingsPercentage ?? 0)}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  savingsPercentage: parseInt(event.target.value) / 100.0,
                });
              }}
            />
            <TextField
              label="Extra Contributions"
              type="number"
              defaultValue={changes.extraContributions?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  extraContributions: new MonetaryAmount(
                    parseInt(event.target.value)
                  ),
                });
              }}
            />
            <Button
              onClick={() => {
                stageChanges(budget.id, changes);
                setShowModal(false);
              }}
            >
              Stage
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ContributorDetails;
