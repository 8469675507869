import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar as MatAvatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Avatar: React.FC<{ [key: string]: any }> = ({ ...props }) => {
  const history = useHistory();
  const { user } = useAuth0();

  return (
    <MatAvatar
      alt={user!.name}
      src={user!.picture}
      onClick={() => history.push("/profile")}
      {...props}
    />
  );
};

export default Avatar;
