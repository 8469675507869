import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const authConfig = {
  domain: "simonschneider.eu.auth0.com",
  clientId: "sVvh7t7XlUQ3OrcyBIX9K97GYrMLttdv",
  audience: "https://pefi.simonschneider.org",
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...authConfig}>
      <App />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
