import React, { MouseEvent, useState } from "react";
import {
  MonetaryAmount,
  MonetaryAmountWithDiff,
} from "../budgets/monetaryAmount";
import { Popover, Typography, withStyles } from "@material-ui/core";
import { formatter } from "../budgets/currency";

const RedTextTypography = withStyles({
  root: {
    color: "red",
  },
})(Typography);

const GreenTextTypography = withStyles({
  root: {
    color: "green",
  },
})(Typography);

const CopyPopover: React.FC<{
  anchorEl: Element | null;
  amount: MonetaryAmount;
  onClose(): void;
}> = ({ anchorEl, amount, onClose }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Typography>Copied {amount.amount}</Typography>
    </Popover>
  );
};

const MoneyWithDiff: React.FC<{
  currency: string;
  amount: MonetaryAmountWithDiff;
}> = ({ currency, amount }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onClose = () => {
    setAnchorEl(null);
  };

  const copy = (event: MouseEvent) => {
    navigator.clipboard.writeText(`${amount.current.amount}`).then(() => {
      setAnchorEl(event.currentTarget);
    });
  };
  const currFmt = formatter(currency);

  if (amount.diff.zero()) {
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <div onClick={copy}>
          <span>{currFmt(amount.current)}</span>
        </div>
        <CopyPopover
          amount={amount.current}
          anchorEl={anchorEl}
          onClose={onClose}
        />
      </div>
    );
  }
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <div
        onClick={copy}
        style={{
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="body1">{currFmt(amount.current)}</Typography>
        {amount.diff.isNeg() ? (
          <RedTextTypography variant="body2">
            {currFmt(amount.diff)}
          </RedTextTypography>
        ) : (
          <GreenTextTypography variant="body2">
            {currFmt(amount.diff)}
          </GreenTextTypography>
        )}
        <Typography variant="body2" color="textSecondary">
          {currFmt(amount.staged)}
        </Typography>
      </div>
      <CopyPopover
        amount={amount.staged}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    </div>
  );
};

export default MoneyWithDiff;
