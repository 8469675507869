import { MonetaryAmount } from "./monetaryAmount";
import { ShareMethodTypeDto } from "./dtos";

export class ShareMethodDiff {
  constructor(current: ShareMethod<any>, staged: ShareMethod<any>) {
    this.current = current;
    this.staged = staged;
  }
  current: ShareMethod<any>;
  staged: ShareMethod<any>;

  hasStagedChanges() {
    return !this.current.equal(this.staged);
  }
}

export interface ShareMethod<T extends ShareMethod<T>> {
  type: ShareMethodTypeDto;

  getAmountToShare(
    numContributors: number,
    income: MonetaryAmount,
    extraContributions: MonetaryAmount
  ): MonetaryAmount;

  copy(opts?: any): T;

  equal(o: ShareMethod<any>): boolean;
}

export class AbsoluteShareMethod implements ShareMethod<AbsoluteShareMethod> {
  type = ShareMethodTypeDto.absolute;
  constructor(sharedAmount: MonetaryAmount) {
    this.sharedAmount = sharedAmount;
  }
  sharedAmount: MonetaryAmount;

  copy(opts?: { absolute?: MonetaryAmount }): AbsoluteShareMethod {
    return new AbsoluteShareMethod(opts?.absolute ?? this.sharedAmount);
  }

  getAmountToShare(
    numContributors: number,
    income: MonetaryAmount,
    extraContributions: MonetaryAmount
  ): MonetaryAmount {
    return this.sharedAmount.divNum(numContributors);
  }

  equal(o: ShareMethod<any>): boolean {
    return (
      o instanceof AbsoluteShareMethod &&
      this.sharedAmount.equal((o as AbsoluteShareMethod).sharedAmount)
    );
  }
}

export class RelativeShareMethod implements ShareMethod<RelativeShareMethod> {
  type = ShareMethodTypeDto.relative;
  constructor(sharePercentage: number) {
    this.sharePercentage = sharePercentage;
  }
  sharePercentage: number;

  getAmountToShare(
    numContributors: number,
    income: MonetaryAmount,
    extraContributions: MonetaryAmount
  ): MonetaryAmount {
    return income
      .add(extraContributions)
      .mul(this.sharePercentage)
      .sub(extraContributions);
  }

  copy(opts?: { relative?: number }): RelativeShareMethod {
    return new RelativeShareMethod(opts?.relative ?? this.sharePercentage);
  }

  equal(o: ShareMethod<any>): boolean {
    return (
      o instanceof RelativeShareMethod &&
      this.sharePercentage === (o as RelativeShareMethod).sharePercentage
    );
  }
}

export class AllowanceShareMethod implements ShareMethod<AllowanceShareMethod> {
  type = ShareMethodTypeDto.allowance;
  constructor(allowance: MonetaryAmount) {
    this.allowance = allowance;
  }
  allowance: MonetaryAmount;

  getAmountToShare(
    numContributors: number,
    income: MonetaryAmount,
    extraContributions: MonetaryAmount
  ): MonetaryAmount {
    return income.sub(this.allowance);
  }

  copy(opts?: { allowance: MonetaryAmount }): AllowanceShareMethod {
    return new AllowanceShareMethod(opts?.allowance ?? this.allowance);
  }

  equal(o: ShareMethod<any>): boolean {
    return (
      o instanceof AllowanceShareMethod &&
      this.allowance.equal((o as AllowanceShareMethod).allowance)
    );
  }
}
