import { MonetaryAmount, MonetaryAmountWithDiff } from "./monetaryAmount";
import { Accounts } from "./accountSplit";
import { ContributorAccountsDiff } from "./household";

export class ContributorAccounts {
  constructor(
    id: string,
    name: string,
    income: MonetaryAmount,
    personalAccounts: Accounts,
    sharedAccounts?: Accounts
  ) {
    this.id = id;
    this.name = name;
    this.income = income;
    this.personalAccounts = personalAccounts;
    this.sharedAccounts = sharedAccounts;
  }

  id: string;
  name: string;
  income: MonetaryAmount;
  personalAccounts: Accounts;
  sharedAccounts?: Accounts;

  diff(o: ContributorAccounts) {
    return new ContributorAccountsDiff(
      this.id,
      this.name,
      new MonetaryAmountWithDiff(this.income, o.income),
      this.personalAccounts.diff(o.personalAccounts),
      o.sharedAccounts ? this.sharedAccounts?.diff(o.sharedAccounts) : undefined
    );
  }
}
