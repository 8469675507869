import { MonetaryAmount, MonetaryAmountWithDiff } from "./monetaryAmount";
import { Account, AccountDiff, Accounts, AccountsDiff } from "./accountSplit";
import { ShareMethodDiff } from "./shareMethod";
import { AccountsSplitDto, ContributorDto } from "./dtos";
import { Pefi } from "./constants";

export interface ContributorCopyOpts {
  income?: MonetaryAmount;
  accounts?: AccountsSplitCopyOpts;
  extraContributions?: MonetaryAmount;
}

export class Contributor {
  constructor(
    id: string,
    name: string,
    income: MonetaryAmount,
    accounts: AccountsSplit,
    extraContributions: MonetaryAmount
  ) {
    this.id = id;
    this.name = name;
    this.income = income;
    this.accounts = accounts;
    this.extraContributions = extraContributions;
  }

  id: string;
  name: string;
  income: MonetaryAmount;
  accounts: AccountsSplit;
  extraContributions: MonetaryAmount;

  copy(opts?: ContributorCopyOpts): Contributor {
    return new Contributor(
      this.id,
      this.name,
      opts?.income ?? this.income,
      this.accounts.copy(opts?.accounts),
      opts?.extraContributions ?? this.extraContributions
    );
  }

  toDto(): ContributorDto {
    return {
      accounts: this.accounts.toDto(),
      id: this.id,
      income: this.income.amount,
      extra_contributions: this.extraContributions.amount,
      name: this.name,
    };
  }
}

export interface AccountProps {
  icon: string;
  color: string;
  name: string;
}

export interface AccountsSplitCopyOpts {
  spending?: MonetaryAmount;
  bills?: MonetaryAmount;
  savings?: number;
}

export class AccountsSplit {
  static from(
    spending: MonetaryAmount,
    bills: MonetaryAmount,
    savings: number
  ) {
    return new AccountsSplit(
      new AbsoluteAccountSplit(Pefi.spending, spending),
      new AbsoluteAccountSplit(Pefi.bills, bills),
      new RemainderAccountSplit(Pefi.shortSavings),
      new RelativeAccountSplit(Pefi.savings, savings)
    );
  }

  private constructor(
    spending: AbsoluteAccountSplit,
    bills: AbsoluteAccountSplit,
    shortSavings: RemainderAccountSplit,
    savings: RelativeAccountSplit
  ) {
    this.spending = spending;
    this.bills = bills;
    this.shortSavings = shortSavings;
    this.savings = savings;
  }

  public spending: AbsoluteAccountSplit;
  public bills: AbsoluteAccountSplit;
  public shortSavings: RemainderAccountSplit;
  public savings: RelativeAccountSplit;

  multiply(m: number) {
    return new AccountsSplit(
      this.spending.multiply(m),
      this.bills.multiply(m),
      this.shortSavings.multiply(m),
      this.savings.multiply(m)
    );
  }

  toAccounts(m: MonetaryAmount) {
    const spending = this.spending.toAccount(m);
    const spendingRem = m.sub(spending.amount);
    const bills = this.bills.toAccount(spendingRem);
    const billsRem = spendingRem.sub(bills.amount);
    const savings = this.savings.toAccount(billsRem);
    const savingsRem = billsRem.sub(savings.amount);
    return new Accounts(
      spending,
      bills,
      this.shortSavings.toAccount(savingsRem),
      savings
    );
  }

  copy(opts?: AccountsSplitCopyOpts) {
    return new AccountsSplit(
      this.spending.copy(opts?.spending),
      this.bills.copy(opts?.bills),
      this.shortSavings.copy(),
      this.savings.copy(opts?.savings)
    );
  }

  toDto(): AccountsSplitDto {
    return {
      bills_amount: this.bills.amount.amount,
      savings_percentage: this.savings.percentage,
      spending_amount: this.spending.amount.amount,
    };
  }
}

export abstract class AccountSplit {
  constructor(props: AccountProps) {
    this.props = props;
  }

  props: AccountProps;

  abstract toAccount(m: MonetaryAmount): Account;

  abstract multiply(m: number): AccountSplit;

  abstract copy(): AccountSplit;
}

export class AbsoluteAccountSplit extends AccountSplit {
  constructor(props: AccountProps, amount: MonetaryAmount) {
    super(props);
    this.amount = amount;
  }

  amount: MonetaryAmount;

  toAccount(m: MonetaryAmount): Account {
    return new Account(this.props, this.amount);
  }

  multiply(m: number): AbsoluteAccountSplit {
    return new AbsoluteAccountSplit(this.props, this.amount.mul(m));
  }

  copy(amount?: MonetaryAmount): AbsoluteAccountSplit {
    return new AbsoluteAccountSplit(this.props, amount ?? this.amount);
  }
}

export class RelativeAccountSplit extends AccountSplit {
  constructor(props: AccountProps, percentage: number) {
    super(props);
    this.percentage = percentage;
  }

  percentage: number;

  toAccount(m: MonetaryAmount): Account {
    return new Account(
      {
        ...this.props,
        name: `${this.props.name} (${Math.floor(this.percentage * 100)}%)`,
      },
      m.mul(this.percentage, 2)
    );
  }

  multiply(m: number): RelativeAccountSplit {
    return this;
  }

  copy(percentage?: number): RelativeAccountSplit {
    return new RelativeAccountSplit(this.props, percentage ?? this.percentage);
  }
}

export class RemainderAccountSplit extends AccountSplit {
  toAccount(m: MonetaryAmount): Account {
    return new Account(this.props, m);
  }

  multiply(m: number): RemainderAccountSplit {
    return this;
  }

  copy(): RemainderAccountSplit {
    return new RemainderAccountSplit(this.props);
  }
}

// OTHER stuff here
export class BudgetDiff {
  constructor(id: string, name: string, accounts: AccountDiff[]) {
    this.id = id;
    this.name = name;
    this.accounts = accounts;
  }

  id: string;
  name: string;
  accounts: AccountDiff[];

  hasStagedChanges(): boolean {
    return this.accounts.some((a) => a.hasStagedChanges());
  }

  total(): MonetaryAmountWithDiff {
    return this.accounts
      .map((a) => a.amount)
      .reduce(
        (acc, e) => acc.add(e),
        new MonetaryAmountWithDiff(new MonetaryAmount(0), new MonetaryAmount(0))
      );
  }
}

export class ContributorBudgetDiff extends BudgetDiff {
  constructor(
    id: string,
    name: string,
    accounts: AccountDiff[],
    income: MonetaryAmountWithDiff
  ) {
    super(id, name, accounts);
    this.income = income;
  }

  income: MonetaryAmountWithDiff;

  hasStagedChanges(): boolean {
    return this.income.hasStagedChanges() || super.hasStagedChanges();
  }
}

export class ContributorAccountsDiff {
  constructor(
    id: string,
    name: string,
    income: MonetaryAmountWithDiff,
    personalAccounts: AccountsDiff,
    sharedAccounts?: AccountsDiff
  ) {
    this.id = id;
    this.name = name;
    this.income = income;
    this.personalAccounts = personalAccounts;
    this.sharedAccounts = sharedAccounts;
  }

  id: string;
  name: string;
  income: MonetaryAmountWithDiff;
  personalAccounts: AccountsDiff;
  sharedAccounts?: AccountsDiff;

  toBudget(): BudgetDiff {
    const accounts = [
      ...this.personalAccounts.toList(),
      ...(this.sharedAccounts?.toList("shared") ?? []),
    ];
    return new ContributorBudgetDiff(this.id, this.name, accounts, this.income);
  }
}

export class SharedBudgetDiff extends BudgetDiff {
  constructor(accounts: AccountDiff[], shareMethod: ShareMethodDiff) {
    super("shared", "Shared", accounts);
    this.shareMethod = shareMethod;
  }

  shareMethod: ShareMethodDiff;

  hasStagedChanges(): boolean {
    return this.shareMethod.hasStagedChanges() || super.hasStagedChanges();
  }
}
