import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { FetchState, withLoading } from "./utils/tryWithState";
import client from "./utils/client";

const HouseholdsList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const [households, setHouseholds] = useState<any[] | undefined>(undefined);
  const [loading, setLoading] = useState<FetchState>(FetchState.IDLE);

  const getHouseholds = withLoading(
    setLoading,
    async (getAccessTokenSilently: () => Promise<string>) =>
      setHouseholds(await client.getHouseholds(await getAccessTokenSilently()))
  );

  useEffect(() => {
    getHouseholds(getAccessTokenSilently);
  }, [getAccessTokenSilently]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading === FetchState.FETCHING || !households) {
    return <Loading />;
  }
  if (!households) {
    return <Loading />;
  }

  const householdList = households
    .map((h) => (
      <ListItem
        button
        key={h.id}
        onClick={() => history.push(`/households/${h.id}`)}
      >
        <ListItemText primary={h.name} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="manage"
            onClick={() => history.push(`/households/${h.id}/manage`)}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))
    .reduce((acc: JSX.Element[], elem: JSX.Element) => {
      return acc.length === 0
        ? [elem]
        : [...acc, <Divider key={`div-${elem.key}`} />, elem];
    }, []);

  return <List>{householdList}</List>;
};

export default HouseholdsList;
