import {
  BudgetDiff,
  Contributor,
  ContributorAccountsDiff,
  ContributorCopyOpts,
} from "../household";
import { ContributorAccounts } from "../budget";
import { HouseholdTypeDto, SingleHouseholdEnvelope } from "../dtos";
import { Household, HouseholdBudget, HouseholdBudgetDiff } from "./household";

export interface SingleHouseholdCopyOpts {
  contributor?: ContributorCopyOpts;
}

export class SingleHousehold implements Household<SingleHousehold> {
  contributor: Contributor;

  constructor(contributor: Contributor) {
    this.contributor = contributor;
  }

  copy(opts?: SingleHouseholdCopyOpts): SingleHousehold {
    return new SingleHousehold(this.contributor.copy(opts?.contributor));
  }

  getContributor(id: string): Contributor | undefined {
    return this.contributor.id === id ? this.contributor : undefined;
  }

  getBudget(): SingleHouseholdBudget {
    const accounts = this.contributor.accounts.toAccounts(
      this.contributor.income
    );
    return new SingleHouseholdBudget(
      new ContributorAccounts(
        this.contributor.id,
        this.contributor.name,
        this.contributor.income,
        accounts,
        undefined
      )
    );
  }

  toDto(): SingleHouseholdEnvelope {
    return {
      type: HouseholdTypeDto.single,
      data: {
        contributor: this.contributor.toDto(),
      },
    };
  }
}

export class SingleHouseholdBudget
  implements HouseholdBudget<SingleHouseholdBudget>
{
  constructor(contributor: ContributorAccounts) {
    this.contributor = contributor;
  }

  contributor: ContributorAccounts;

  diff(o: SingleHouseholdBudget): HouseholdBudgetDiff {
    return new SingleHouseholdBudgetDiff(this.contributor.diff(o.contributor));
  }
}

export class SingleHouseholdBudgetDiff implements HouseholdBudgetDiff {
  constructor(contributor: ContributorAccountsDiff) {
    this.contributor = contributor;
  }

  contributor: ContributorAccountsDiff;

  toBudgets(): BudgetDiff[] {
    return [this.contributor.toBudget()];
  }
}
