import React from "react";
import HouseholdsList from "./HouseholdsList";
import AddHousehold from "./AddHousehold";

const Households = () => {
  return (
    <div>
      <HouseholdsList />
      <div style={{ position: "absolute", bottom: 0, right: 0 }}>
        <AddHousehold />
      </div>
    </div>
  );
};

export default Households;
