import AccountsListWithHeader from "./components/AccountsListWithHeader";
import React, { useState } from "react";
import { SharedBudgetDiff } from "./budgets/household";
import PefiListItem from "./components/PefiListItem";
import { AccountsChanges } from "./ContributorDetails";
import { ShareMethodTypeDto } from "./budgets/dtos";
import { MonetaryAmount } from "./budgets/monetaryAmount";
import {
  Button,
  Fab,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export interface ShareMethodChanges {
  shareMethod?: ShareMethodTypeDto;
  shareAllowance?: MonetaryAmount;
  sharePercentage?: number;
  shareAmount?: MonetaryAmount;
}

export interface SharedChanges extends ShareMethodChanges, AccountsChanges {}

const SharedDetails: React.FC<{
  currency: string;
  budget: SharedBudgetDiff;
  details: SharedChanges;
  stageChanges: (c: SharedChanges) => void;
  addContributor: (id: string, name: string) => void;
}> = ({ currency, budget, details, stageChanges, addContributor }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showAddContributorModal, setShowAddContributorModal] = useState(false);
  const [changes, setChanges] = useState<SharedChanges>({ ...details });
  const [addContributorDetails, setAddContributorDetails] = useState<{
    id?: string;
    name?: string;
  }>({});
  return (
    <div>
      <AccountsListWithHeader currency={currency} budget={budget}>
        <PefiListItem
          key={"shareMethod"}
          heading={`Share Method (${budget.shareMethod.current.type})`}
          right={""}
        />
      </AccountsListWithHeader>
      <Fab
        color="primary"
        aria-label="edit"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.margin}
      >
        <EditIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="edit"
        aria-haspopup="true"
        onClick={() => setShowAddContributorModal(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.margin}
      >
        <AddIcon />
      </Fab>
      <Modal
        open={showAddContributorModal}
        onClose={() => setShowAddContributorModal(false)}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Add contributor</h2>
          <form
            autoComplete="off"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              label="id"
              type="text"
              defaultValue=""
              onChange={(event) => {
                setAddContributorDetails({
                  ...addContributorDetails,
                  id: event.target.value,
                });
              }}
            />
            <TextField
              label="name"
              type="text"
              defaultValue=""
              onChange={(event) => {
                setAddContributorDetails({
                  ...addContributorDetails,
                  name: event.target.value,
                });
              }}
            />
            <Button
              onClick={() => {
                addContributor(
                  addContributorDetails.id!,
                  addContributorDetails.name!
                );
                setShowModal(false);
              }}
            >
              Stage
            </Button>
          </form>
        </div>
      </Modal>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Edit shared accounts</h2>
          <form
            autoComplete="off"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              type="text"
              value={changes.shareMethod}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  shareMethod:
                    ShareMethodTypeDto[
                      `${event.target.value}` as keyof typeof ShareMethodTypeDto
                    ],
                });
              }}
            >
              <MenuItem value={ShareMethodTypeDto.relative}>Relative</MenuItem>
              <MenuItem value={ShareMethodTypeDto.absolute}>Absolute</MenuItem>
              <MenuItem value={ShareMethodTypeDto.allowance}>
                Allowance
              </MenuItem>
            </Select>
            <TextField
              label="Share Percentage"
              type="number"
              defaultValue={100 * (changes.sharePercentage ?? 0)}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  sharePercentage: parseInt(event.target.value) / 100.0,
                });
              }}
            />
            <TextField
              label="Share Allowance"
              type="number"
              defaultValue={changes.shareAllowance?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  shareAllowance: new MonetaryAmount(
                    parseInt(event.target.value)
                  ),
                });
              }}
            />
            <TextField
              label="Share Amount"
              type="number"
              defaultValue={changes.shareAmount?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  shareAmount: new MonetaryAmount(parseInt(event.target.value)),
                });
              }}
            />
            <TextField
              label="Spending"
              type="number"
              defaultValue={changes.spendingAmount?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  spendingAmount: new MonetaryAmount(
                    parseInt(event.target.value)
                  ),
                });
              }}
            />
            <TextField
              label="Bills"
              type="number"
              defaultValue={changes.billsAmount?.amount}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  billsAmount: new MonetaryAmount(parseInt(event.target.value)),
                });
              }}
            />
            <TextField
              label="Savings %"
              type="number"
              defaultValue={100 * (changes.savingsPercentage ?? 0)}
              onChange={(event) => {
                setChanges({
                  ...changes,
                  savingsPercentage: parseInt(event.target.value) / 100.0,
                });
              }}
            />
            <Button
              onClick={() => {
                stageChanges(changes);
                setShowModal(false);
              }}
            >
              Stage
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SharedDetails;
