import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const classes = useStyles();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (!isAuthenticated || !user) {
    return <span />;
  }
  return (
    <div className={classes.root}>
      <List>
        <ListItem>
          <ListItemText primary={user.name} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={user.email} />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <ListItemText primary={"logout"} />
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );
};

export default Profile;
