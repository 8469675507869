import MaterialAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar as MaterialToolbar, Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./components/LoginButton";
import Avatar from "./components/Avatar";
import Loading from "./components/Loading";
import { useHistory } from "react-router-dom";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

const Toolbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth0();
  if (!isAuthenticated) {
    return (
      <MaterialToolbar>
        <Typography
          variant="h6"
          className={classes.title}
          onClick={() => history.push("/")}
        >
          Pefi
        </Typography>
        {isLoading ? <Loading /> : <LoginButton color="inherit" edge="end" />}
      </MaterialToolbar>
    );
  }
  return (
    <MaterialToolbar>
      <Typography
        variant="h6"
        className={classes.title}
        onClick={() => history.push("/")}
      >
        Pefi
      </Typography>
      <Avatar edge="end" />
    </MaterialToolbar>
  );
};

const AppBar = () => {
  return (
    <React.Fragment>
      <MaterialAppBar position="sticky">
        <Toolbar />
      </MaterialAppBar>
    </React.Fragment>
  );
};

export default AppBar;
