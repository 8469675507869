import {
  AccountsSplit,
  AccountsSplitCopyOpts,
  BudgetDiff,
  Contributor,
  ContributorAccountsDiff,
  ContributorCopyOpts,
  SharedBudgetDiff,
} from "../household";
import {
  HouseholdTypeDto,
  MultiHouseholdEnvelope,
  ShareMethodTypeDto,
} from "../dtos";
import { MonetaryAmount } from "../monetaryAmount";
import {
  AbsoluteShareMethod,
  AllowanceShareMethod,
  RelativeShareMethod,
  ShareMethod,
  ShareMethodDiff,
} from "../shareMethod";
import { ContributorAccounts } from "../budget";
import { Accounts, AccountsDiff } from "../accountSplit";
import { Household, HouseholdBudget, HouseholdBudgetDiff } from "./household";

export interface MultiHouseholdCopyOpts {
  contributors?: {
    [k: string]: ContributorCopyOpts;
  };
  activeShareMethod?: ShareMethodTypeDto;
  accounts?: AccountsSplitCopyOpts;
  shareMethods?: {
    absolute?: MonetaryAmount;
    allowance?: MonetaryAmount;
    relative?: number;
  };
}

export class MultiHousehold implements Household<MultiHousehold> {
  constructor(
    contributors: Contributor[],
    activeShareMethod: ShareMethodTypeDto,
    shareMethods: ShareMethod<any>[],
    shared: AccountsSplit
  ) {
    this.contributors = contributors;
    this.activeShareMethod = activeShareMethod;
    this.shareMethods = shareMethods;
    this.shared = shared;
  }

  activeShareMethod: ShareMethodTypeDto;
  contributors: Contributor[];
  shareMethods: ShareMethod<any>[];
  shared: AccountsSplit;

  addContributor(id: string, name: string): MultiHousehold {
    const existing = this.getContributor(id);
    if (existing) {
      throw new Error("already has contributor with this ID");
    }
    return new MultiHousehold(
      [
        ...this.contributors,
        new Contributor(
          id,
          name,
          new MonetaryAmount(0),
          AccountsSplit.from(new MonetaryAmount(0), new MonetaryAmount(0), 0),
          new MonetaryAmount(0)
        ),
      ],
      this.activeShareMethod,
      this.shareMethods,
      this.shared
    );
  }

  copy(opts?: MultiHouseholdCopyOpts): MultiHousehold {
    return new MultiHousehold(
      this.contributors.map((c) => c.copy(opts?.contributors?.[c.id])),
      opts?.activeShareMethod ?? this.activeShareMethod,
      this.shareMethods.map((m) => m.copy(opts?.shareMethods)),
      this.shared.copy(opts?.accounts)
    );
  }

  getContributor(id: string): Contributor | undefined {
    return this.contributors.find((c) => c.id === id);
  }

  getBudget(): MultiHouseholdBudget {
    const shareMethod = this.shareMethods.find(
      (s) => s.type === this.activeShareMethod
    )!;
    const conts = Object.values(this.contributors).map((c) => {
      const sharedAmount = shareMethod.getAmountToShare(
        this.contributors.length,
        c.income,
        c.extraContributions
      );
      const total = this.contributors
        .map((c) => c.income)
        .reduce((a, b) => a.add(b), new MonetaryAmount(0));
      const sharedAcc = this.shared
        .multiply(c.income.div(total))
        .toAccounts(sharedAmount);
      const personalAcc = c.accounts.toAccounts(c.income.sub(sharedAmount));
      return new ContributorAccounts(
        c.id,
        c.name,
        c.income,
        personalAcc,
        sharedAcc
      );
    });
    if (conts.length > 0) {
      const sharedAcc = conts
        .map((c) => c.sharedAccounts)
        .reduce((a, b) => a!.plus(b!))!;
      return new MultiHouseholdBudget(conts, shareMethod, sharedAcc);
    }
    return new MultiHouseholdBudget([], shareMethod, new Accounts());
  }

  toDto(): MultiHouseholdEnvelope {
    return {
      type: HouseholdTypeDto.multi,
      data: {
        share_method: this.activeShareMethod,
        share_amount: (
          this.shareMethods.find(
            (s) => s.type === ShareMethodTypeDto.absolute
          ) as AbsoluteShareMethod
        ).sharedAmount.amount,
        share_allowance: (
          this.shareMethods.find(
            (s) => s.type === ShareMethodTypeDto.allowance
          ) as AllowanceShareMethod
        ).allowance.amount,
        share_percentage: (
          this.shareMethods.find(
            (s) => s.type === ShareMethodTypeDto.relative
          ) as RelativeShareMethod
        ).sharePercentage,
        accounts_split: this.shared.toDto(),
        contributors: this.contributors.map((c) => c.toDto()),
      },
    };
  }
}

export class MultiHouseholdBudget
  implements HouseholdBudget<MultiHouseholdBudget>
{
  constructor(
    contributors: ContributorAccounts[],
    shareMethod: ShareMethod<any>,
    shared: Accounts
  ) {
    this.contributors = contributors;
    this.shareMethod = shareMethod;
    this.shared = shared;
  }

  contributors: ContributorAccounts[];
  shareMethod: ShareMethod<any>;
  shared: Accounts;

  diff(o: MultiHouseholdBudget): HouseholdBudgetDiff {
    const contDiff = this.contributors.map((c) =>
      c.diff(o.contributors.find((oc) => oc.id === c.id)!)
    );
    return new MultiHouseholdBudgetDiff(
      contDiff,
      new ShareMethodDiff(this.shareMethod, o.shareMethod),
      this.shared.diff(o.shared)
    );
  }
}

export class MultiHouseholdBudgetDiff implements HouseholdBudgetDiff {
  constructor(
    contributors: ContributorAccountsDiff[],
    shareMethod: ShareMethodDiff,
    shared: AccountsDiff
  ) {
    this.contributors = contributors;
    this.shareMethod = shareMethod;
    this.shared = shared;
  }

  contributors: ContributorAccountsDiff[];
  shareMethod: ShareMethodDiff;
  shared: AccountsDiff;

  toBudgets(): BudgetDiff[] {
    return [
      new SharedBudgetDiff(this.shared.toList(), this.shareMethod),
      ...this.contributors.map((c) => c.toBudget()),
    ];
  }
}
