import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";

interface Props {
  [x: string]: any;
}

const LoginButton: React.FC<Props> = ({ ...props }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button onClick={() => loginWithRedirect()} {...props}>
      Log In
    </Button>
  );
};

export default LoginButton;
