import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "10px",
  },
  secondaryAction: {
    textAlign: "right",
    position: "relative",
    top: "inherit",
    right: "inherit",
    transform: "inherit",
  },
  listItemText: {
    marginTop: "0",
    marginBottom: "0",
  },
}));

const PefiListItem: React.FC<{
  icon?: ReactNode;
  heading: ReactNode;
  subHeading?: ReactNode;
  right: ReactNode;
}> = ({ icon, heading, subHeading, right }) => {
  const classes = useStyles();
  return (
    <ListItem
      classes={{
        container: classes.listContainer,
        root: classes.listItem,
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={heading}
        secondary={subHeading}
        classes={{
          root: classes.listItemText,
          multiline: classes.listItemText,
        }}
      />
      <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
        {right}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PefiListItem;
