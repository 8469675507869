export enum FetchState {
  IDLE,
  FETCHING,
}

export const tryWithState = async (
  setCurrentState: (n: FetchState) => void,
  cb: () => Promise<void>,
  reportError: (e: string) => void = (e) => {
    console.log(e);
  }
) => {
  setCurrentState(FetchState.FETCHING);
  try {
    await cb();
  } catch (error) {
    reportError(error);
  } finally {
    setCurrentState(FetchState.IDLE);
  }
};

export const withLoading =
  <T extends any[]>(
    setCurrentState: (n: FetchState) => void,
    cb: (...t: T) => Promise<void>,
    reportError: (e: string) => void = (e) => {}
  ) =>
  async (...t: T) =>
    tryWithState(setCurrentState, async () => await cb(...t), reportError);
